import React, { useCallback, useState } from "react"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"

export default function GalleryPageTemplate(props) {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const intl = useIntl()

  const openLightbox = useCallback((event, { index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const photos = props.pageContext.en.images.map((img, i) => {
    const fa = props.pageContext.fa.images[i]
    const alt =
      intl.locale === "en"
        ? `${img.description} - ${img.title}`
        : `${fa.description} - ${fa.title}`
    return {
      src: img.fluid.src,
      width: img.file.details.image.width,
      height: img.file.details.image.height,
      alt,
    }
  })

  return (
    <>
      <SEO
        title={props.pageContext[intl.locale].name}
        description={`${intl.formatMessage({ id: "galleryDescription" })} - ${
          props.pageContext[intl.locale].name
        }`}
      />

      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="pages-title">{props.pageContext[intl.locale].name}</h1>
            <Gallery photos={photos} margin={3} onClick={openLightbox} />
          </div>
        </div>
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  caption: x.alt,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </>
  )
}
